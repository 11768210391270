import React from 'react';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { GridWrapper } from 'src/components/GridWrapper';

const SRight = styled.div`
    margin-left: 0.8rem;
    flex: 1;

    @media (max-width: 768px), (orientation: portrait) {
        margin-left: unset;
    }
`;

const SLeft = styled.div`
    flex: 1;

    @media (max-width: 768px), (orientation: portrait) {
        margin-bottom: 0.8rem;
    }
`;

const SImage = styled(GatsbyImage)`
    height: 100%;
`;

export const GridTwo = ({ right, left, projectName }) => {
    return (
        <GridWrapper>
            <SLeft>
                <SImage image={getImage(left)} alt={projectName} width="100%" height="100%" />
            </SLeft>
            <SRight>
                <SImage image={getImage(right)} alt={projectName} width="100%" height="100%" />
            </SRight>
        </GridWrapper>
    );
};
