import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { PROJECTS } from 'constants/projects';
import { ProjectMainLayout } from 'layouts/ProjectMainLayout';
import { GridTwo } from 'components/GridTwo';

export const FashionView = () => {
    const images = useStaticQuery(graphql`
        query FashionImages {
            left: file(name: { eq: "fashion-left" }) {
                childImageSharp {
                    gatsbyImageData(quality: 100)
                }
            }
            right: file(name: { eq: "fashion-right" }) {
                childImageSharp {
                    gatsbyImageData(quality: 100)
                }
            }
        }
    `);

    return (
        <ProjectMainLayout projectName={PROJECTS.fashion.name}>
            <GridTwo {...images} projectName={PROJECTS.fashion.name} />
        </ProjectMainLayout>
    );
};
